import type { ResultType } from '@/app/lib/helpers/Result';
import { Text } from '@/app/lib/helpers/Text';

// eslint-disable-next-line
export class Markdown {
	static GetMarkdownSyntaxFromFileName(path: string) {
		const extDict: Record<string, string[]> = {
			abap: ['.abap'],
			ada: ['.adb', '.ads', '.ada'],
			ahk: ['.ahk', '.ahkl'],
			apacheconf: ['.htaccess', 'apache.conf', 'apache2.conf'],
			applescript: ['.applescript'],
			as: ['.as'],
			as3: ['.as'],
			asy: ['.asy'],
			bash: ['.sh', '.ksh', '.bash', '.ebuild', '.eclass'],
			bat: ['.bat', '.cmd'],
			befunge: ['.befunge'],
			blitzmax: ['.bmx'],
			boo: ['.boo'],
			brainfuck: ['.bf', '.b'],
			c: ['.c', '.h'],
			cfm: ['.cfm', '.cfml', '.cfc'],
			cheetah: ['.tmpl', '.spt'],
			cl: ['.cl', '.lisp', '.el'],
			clojure: ['.clj', '.cljs'],
			cmake: ['.cmake', 'CMakeLists.txt'],
			coffeescript: ['.coffee'],
			console: ['.sh-session'],
			control: ['control'],
			cpp: ['.cpp', '.hpp', '.c++', '.h++', '.cc', '.hh', '.cxx', '.hxx', '.pde'],
			csharp: ['.cs'],
			css: ['.css'],
			cython: ['.pyx', '.pxd', '.pxi'],
			d: ['.d', '.di'],
			delphi: ['.pas'],
			diff: ['.diff', '.patch'],
			dpatch: ['.dpatch', '.darcspatch'],
			duel: ['.duel', '.jbst'],
			dylan: ['.dylan', '.dyl'],
			erb: ['.erb'],
			erl: ['.erl-sh'],
			erlang: ['.erl', '.hrl'],
			evoque: ['.evoque'],
			factor: ['.factor'],
			felix: ['.flx', '.flxh'],
			fortran: ['.f', '.f90'],
			gas: ['.s', '.S'],
			genshi: ['.kid'],
			gitignore: ['.gitignore'],
			glsl: ['.vert', '.frag', '.geo'],
			gnuplot: ['.plot', '.plt'],
			go: ['.go'],
			groff: ['.(1234567)', '.man'],
			haml: ['.haml'],
			haskell: ['.hs'],
			html: ['.html', '.htm', '.xhtml', '.xslt'],
			hx: ['.hx'],
			hybris: ['.hy', '.hyb'],
			ini: ['.ini', '.cfg'],
			io: ['.io'],
			ioke: ['.ik'],
			irc: ['.weechatlog'],
			jade: ['.jade'],
			java: ['.java'],
			js: ['.js', '.jsx'],
			json: ['.json'],
			jsp: ['.jsp'],
			lhs: ['.lhs'],
			llvm: ['.ll'],
			logtalk: ['.lgt'],
			lua: ['.lua', '.wlua'],
			make: ['.mak', 'Makefile', 'makefile', 'Makefile.', 'GNUmakefile'],
			mako: ['.mao'],
			maql: ['.maql'],
			mason: ['.mhtml', '.mc', '.mi', 'autohandler', 'dhandler'],
			markdown: ['.md'],
			modelica: ['.mo'],
			modula2: ['.def', '.mod'],
			moocode: ['.moo'],
			mupad: ['.mu'],
			mxml: ['.mxml'],
			myghty: ['.myt', 'autodelegate'],
			nasm: ['.asm', '.ASM'],
			newspeak: ['.ns2'],
			objdump: ['.objdump'],
			objectivec: ['.m'],
			objectivej: ['.j'],
			ocaml: ['.ml', '.mli', '.mll', '.mly'],
			ooc: ['.ooc'],
			perl: ['.pl', '.pm'],
			php: ['.php', '.php(345)'],
			postscript: ['.ps', '.eps'],
			pot: ['.pot', '.po'],
			pov: ['.pov', '.inc'],
			prolog: ['.prolog', '.pro', '.pl'],
			properties: ['.properties'],
			protobuf: ['.proto'],
			py3tb: ['.py3tb'],
			pytb: ['.pytb'],
			python: ['.py', '.pyw', '.sc', 'SConstruct', 'SConscript', '.tac'],
			r: ['.R'],
			rb: ['.rb', '.rbw', 'Rakefile', '.rake', '.gemspec', '.rbx', '.duby'],
			rconsole: ['.Rout'],
			rebol: ['.r', '.r3'],
			redcode: ['.cw'],
			rhtml: ['.rhtml'],
			rst: ['.rst', '.rest'],
			sass: ['.sass'],
			scala: ['.scala'],
			scaml: ['.scaml'],
			scheme: ['.scm'],
			scss: ['.scss'],
			smalltalk: ['.st'],
			smarty: ['.tpl'],
			sourceslist: ['sources.list'],
			splus: ['.S', '.R'],
			sql: ['.sql'],
			sqlite3: ['.sqlite3-console'],
			squidconf: ['squid.conf'],
			ssp: ['.ssp'],
			tcl: ['.tcl'],
			tcsh: ['.tcsh', '.csh'],
			tex: ['.tex', '.aux', '.toc'],
			text: ['.txt'],
			typescript: ['.ts'],
			v: ['.v', '.sv'],
			vala: ['.vala', '.vapi'],
			vbnet: ['.vb', '.bas'],
			velocity: ['.vm', '.fhtml'],
			vim: ['.vim', '.vimrc'],
			xml: ['.xml', '.xsl', '.rss', '.xslt', '.xsd', '.wsdl'],
			xquery: ['.xqy', '.xquery'],
			xslt: ['.xsl', '.xslt'],
			yaml: ['.yaml', '.yml'],
		};

		if (path) {
			const lower = path.toLocaleLowerCase();
			for (const key in extDict) {
				if (Object.prototype.hasOwnProperty.call(extDict, key)) {
					const element = extDict[key];
					for (const ext of element) {
						if (lower.endsWith(ext)) {
							return key;
						}
					}
				}
			}
		}
		return undefined;
	}

	static ExtractCommands(message: string, markdownType = 'bash') {
		const matches: string[] = [];
		let unparsedData = message;
		let command = Text.ExtractGeneral(unparsedData, '```' + markdownType, '```', true, false, false);
		while (command) {
			if (command) {
				matches.push(command);
			}
			unparsedData = unparsedData.substring(unparsedData.indexOf(command) + command.length);
			command = Text.ExtractGeneral(unparsedData, '```' + markdownType, '```', true, false, false);
		}
		return matches;
	}

	static Extract(message: string) {
		if (message?.includes('```')) {
			return Text.ExtractGeneral(message, '```', '```', true, false, true);
		}
		return Text.ExtractGeneral(message, '`', '`', false, false, false);
	}

	static ExtractFromResult(message: ResultType<string, string>) {
		if (message.ok) {
			return Markdown.Extract(message.value);
		}
		return undefined;
	}

	// eslint-disable-next-line
	static ExtractEnumFromResult<T>(message: ResultType<string, string>): T | undefined {
		let extracted: T | undefined = undefined;
		if (message.ok) {
			extracted = Markdown.Extract(message.value)?.trim()?.toLocaleUpperCase() as unknown as T;
		}
		return extracted;
	}
}
