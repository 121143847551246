import { Constants } from '@/app/lib/Constants';
import type { BuildCostSummary } from '@/app/lib/dtos/BuildCostSummary';
import type { ClientSocketEmit } from '@/app/lib/dtos/ClientSocketEmit';
import type { BuildWithInfo } from '@/app/lib/dtos/ServerSocketEmit';
import type { ToolCallRequest } from '@/app/lib/dtos/ToolCallRequest';
import type { ToolCallResponse } from '@/app/lib/dtos/ToolCallResponse';
import { Build } from '@/app/lib/entities/Build.entity';
import type { BuildFile } from '@/app/lib/entities/BuildFile.entity';
import type { BuildStep } from '@/app/lib/entities/BuildStep.entity';
import type { ChatMessage, PromptString } from '@/app/lib/entities/ChatMessage.entity';
import type { Thread } from '@/app/lib/entities/Thread.entity';
import type { UserId } from '@/app/lib/entities/User.entity';
import { Markdown } from '@/app/lib/helpers/Markdown';
import { Text } from '@/app/lib/helpers/Text';
import { Users } from '@/app/lib/helpers/Users';
import { Shared } from '@/app/lib/Shared';
import { type ToolType } from '@/app/lib/types/ToolType';
import type { User, UserType } from '@/app/lib/types/User';
import { socket } from '@/app/socket';
import { SVGAvatar1 } from '@/components/svg/avatar1';
import { SVGAvatar2 } from '@/components/svg/avatar2';
import { SVGAvatar3 } from '@/components/svg/avatar3';
import { SVGAvatar4 } from '@/components/svg/avatar4';
import { SVGAvatar5 } from '@/components/svg/avatar5';
import { SVGAvatar6 } from '@/components/svg/avatar6';
import { SVGProfile } from '@/components/svg/profile';
import { Tool } from '@/components/svg/tool';
import { marked } from 'marked';
import type React from 'react';

export interface ToolDisplayProps {
	from: User;
	message: string;
	toolName: ToolType;
	profileImage: () => React.JSX.Element;
}

// eslint-disable-next-line
export class Client {
	static CreateMessage(content: string, thread: Thread): Partial<ChatMessage> {
		const toUser = determineRecipient(content);

		return {
			datePosted: new Date(),
			content: content as PromptString,
			fromUser: 'user',
			contentType: 'text',
			toUser,
			threadId: thread.id,
		};
	}

	static Emit(message: ClientSocketEmit) {
		socket.emit(message.type, message);
	}

	static SetUserId(userId: UserId) {
		if (globalThis.localStorage) {
			globalThis.localStorage.setItem('userId', userId);
		}
	}

	static GetUserId(): UserId | undefined {
		if (globalThis.localStorage) {
			return (globalThis.localStorage.getItem('userId') ?? undefined) as UserId | undefined;
		}
		return undefined;
	}

	static ToLegibleDate(maybeDate: Date | undefined): string {
		let legibleDate = '';

		if (maybeDate) {
			const date = new Date(maybeDate);

			if (!isNaN(Number(date))) {
				const today = new Date();
				const todayStart = new Date(today.getFullYear(), today.getMonth(), today.getDate());

				if (date < todayStart) {
					legibleDate = date.toLocaleString();
				} else {
					legibleDate = date.toLocaleTimeString();
				}
			}
		}

		return legibleDate;
	}

	static DetermineBuildState(build: Build): string {
		if (build.cancellationState == 'cancelled') {
			return 'Paused';
		} else if (build.statusMajor == 'awaiting') {
			return 'Waiting to start';
		} else if (build.statusMajor == 'working') {
			return 'Working';
		}
		return 'Completed';
	}

	static DetermineBuildType(build: Build): string {
		if (build.buildType == 'preview') {
			return 'Preview';
		} else if (build.buildType == 'amend-full-production' || build.buildType == 'amend-prototype') {
			return 'Applying requested changes';
		} else if (build.buildType == 'prototype') {
			return 'Prototype';
		}
		return 'Full Production Build';
	}

	static DetermineBuildStepState(step: BuildStep): string {
		let response = '';

		if (step?.step == 'create-git-repository') {
			response = 'Created a git repository';
		} else if (step?.step == 'clone-git-repository') {
			response = 'Cloned the git repository';
		} else if (step?.step == 'clear-no-status') {
			response = 'Cleared the build state';
		} else if (step?.step == 'create-project-brief') {
			response = 'Created a project brief';
		} else if (step?.step == 'determine-project-complexity') {
			response = 'Determined project complexity';
		} else if (step?.step == 'create-project-architecture') {
			response = 'Created an application architecture';
		} else if (step?.step == 'write-project-code') {
			response = 'Generated the code files';
		} else if (step?.step == 'write-project-code-with-amendments') {
			response = 'Generated the code files';
		} else if (step?.step == 'write-project-unit-tests') {
			response = 'Generated the unit tests';
		} else if (step?.step == 'install-project-dependencies') {
			response = 'Installed application dependencies';
		} else if (step?.step == 'run-linting') {
			response = 'Linted the code files';
		} else if (step?.step == 'run-unit-tests') {
			response = 'Ran application test suite';
		} else if (step?.step == 'build-project') {
			response = 'Built the application';
		} else if (step?.step == 'start-project') {
			response = 'Started the application';
		} else if (step?.step == 'check-web-application-basic-connectivity') {
			response = 'Validated application connectivity';
		} else if (step?.step == 'check-web-application-all-pages-thoroughly') {
			response = 'Validated application routes';
		} else if (step?.step == 'x-ask-user-question') {
			response = 'Asked a question';
		} else if (step?.step == 'x-start-dev-in-middle') {
			response = 'Asked a human engineer';
		}

		return response;
	}

	static DetermineWorkingBuildState(build: Build): string {
		let response = '';
		if (build.cancellationState == 'cancelled') {
			response = 'The build is paused';
		} else if (build.statusMajor == 'working') {
			response += `The build process has ${build.statusPatch == '1-started' ? 'started' : 'finished'} `;
			response += Constants.DisplayMapBuildStateStatusMinor[build.statusMinor];
		}
		return response;
	}

	static GetProfileImage = (userType: UserType | 'tool') => {
		let profileImage = SVGProfile;
		if (userType == 'projectManager') {
			profileImage = SVGAvatar1;
		} else if (userType == 'designer') {
			profileImage = SVGAvatar2;
		} else if (userType == 'architect') {
			profileImage = SVGAvatar3;
		} else if (userType == 'devops') {
			profileImage = SVGAvatar4;
		} else if (userType == 'engineer') {
			profileImage = SVGAvatar5;
		} else if (userType == 'tester') {
			profileImage = SVGAvatar6;
		} else if (userType == 'tool') {
			profileImage = Tool;
		}
		return profileImage;
	};

	static GetToolArguments(request: ToolCallRequest): Record<string, string> {
		const result: Record<string, string> = {};

		if (
			request.toolName == 'ask-architect' ||
			request.toolName == 'ask-designer' ||
			request.toolName == 'ask-devops' ||
			request.toolName == 'ask-engineer' ||
			request.toolName == 'ask-project-manager' ||
			request.toolName == 'ask-tester' ||
			request.toolName == 'create-specific-design' ||
			request.toolName == 'edit-design' ||
			request.toolName == 'ask-pm-in-build' ||
			request.toolName == 'ask-engineer-in-build'
		) {
			result.Question = request.arguments.question;
		} else if (request.toolName == 'execute-command-line') {
			result.Command = request.arguments.command;
		} else if (
			request.toolName == 'overwrite-file' ||
			request.toolName == 'create-new-file' ||
			request.toolName == 'delete-file' ||
			request.toolName == 'insert-line-in-file' ||
			request.toolName == 'view-file' ||
			request.toolName == 'search-replace-in-file'
		) {
			result.Path = request.arguments.path;
		} else if (request.toolName == 'google-search') {
			result.Term = request.arguments.term;
		} else if (
			request.toolName == 'get-web-page' ||
			request.toolName == 'get-web-page-with-detail' ||
			request.toolName == 'web-page-interact-with-detail' ||
			request.toolName == 'make-http-request'
		) {
			result.Url = request.arguments.url;
		}
		return result;
	}

	static GetMessageBody(message: ChatMessage, file: BuildFile | undefined): React.JSX.Element | undefined {
		const { context, fromUser, content, contentType } = message;
		let text: string | undefined = undefined;
		let markdown: string | undefined = undefined;
		let deleteContent = true;

		if (context) {
			if (context == 'request-low-level-architecture') {
				if (fromUser == 'user') {
					text = 'Generating Low Level Project Architecture';
				} else {
					text = 'Low Level Project Architecture created';
				}
			} else if (context == 'request-high-level-architecture') {
				if (fromUser == 'user') {
					text = 'Generating High Level Project Architecture';
				} else {
					text = 'High Level Project Architecture created';
				}
			} else if (context == 'request-build-command') {
				if (fromUser == 'user') {
					text = 'Obtaining the command to build the project';
				} else {
					deleteContent = false;
					text = 'Command to build the project';
					markdown = content as string;
				}
			} else if (context == 'request-fix-plan') {
				if (fromUser == 'user') {
					deleteContent = false;
					text = 'Obtaining the plan to fix the problem';
					if (typeof content == 'string') {
						markdown = Markdown.Extract(content);
					}
				} else {
					text = 'Obtained the plan to fix the problem';
				}
			} else if (context == 'request-fix-plan-step-execute') {
				if (fromUser == 'user') {
					text = 'Execute step in plan to fix problem';
				} else {
					text = 'Executed step in plan';
				}
			} else if (context == 'request-fix-plan-steps') {
				if (fromUser == 'user') {
					text = 'Ask for steps in the plan to fix problem';
				} else {
					text = 'Identified the steps in the plan';
				}
			} else if (context == 'request-hosting-command') {
				if (fromUser == 'user') {
					text = 'Obtaining the command to start the project';
				} else {
					deleteContent = false;
					text = 'Command to start the project';
					markdown = content as string;
				}
			} else if (context == 'request-installation-command') {
				if (fromUser == 'user') {
					text = 'Obtaining the command to install the project';
				} else {
					deleteContent = false;
					text = 'Command to install the project';
					markdown = content as string;
				}
			} else if (context == 'request-linting-command') {
				if (fromUser == 'user') {
					text = 'Obtaining the command to lint the project';
				} else {
					deleteContent = false;
					text = 'Command to lint the project';
					markdown = content as string;
				}
			} else if (context == 'run-linting-command') {
				if (fromUser == 'user') {
					text = 'Linting the project';
				} else {
					text = 'Linted the project';
				}
			} else if (context == 'request-order-of-implementation') {
				if (fromUser == 'user') {
					text = 'Organizing project file structure';
				} else {
					text = 'Project file structure organized';
				}
			} else if (context == 'request-project-brief') {
				if (fromUser == 'user') {
					text = 'Gathering the project detail';
				} else {
					text = 'Gathered the project detail';
				}
			} else if (context == 'request-proposed-file-structure') {
				if (fromUser == 'user') {
					text = 'Generating project file structure';
				} else {
					text = 'Generated project file structure';
				}
			} else if (context == 'request-summarization') {
				if (fromUser == 'user') {
					text = 'Requested Summarization';
				} else {
					text = 'Generated Summarization';
				}
			} else if (context == 'request-testing-command') {
				if (fromUser == 'user') {
					text = 'Obtaining the command to test the project';
				} else {
					deleteContent = false;
					text = 'Command to test the project';
					markdown = content as string;
				}
			} else if (context == 'write-code') {
				if (fromUser == 'user') {
					deleteContent = false;
					if (typeof content == 'string') {
						text = `Generating code for ${new RegExp('Create the code for the file "([^"].*?)"').exec(Shared.ToString(content))?.[1] ?? ''}`;
					}
				} else {
					text = 'Code generated';
				}
			} else if (context == 'write-test-code') {
				if (fromUser == 'user') {
					deleteContent = false;
					if (typeof content == 'string') {
						text = `Generating test code for ${new RegExp('create the code for the file "([^"].*?)"').exec(Shared.ToString(content))?.[1] ?? ''}`;
					}
				} else {
					text = 'Test code written';
				}
			} else if (context == 'request-applicable-files') {
				if (fromUser == 'user') {
					text = 'Querying for applicable files';
				} else {
					text = 'Obtained applicable files';
				}
			} else if (context == 'directory-listing') {
				if (fromUser == 'user') {
					text = 'Generating directory-listing';
				} else {
					text = 'Generated directory-listing';
				}
			} else if (context == 'request-shell-fix-score') {
				if (fromUser == 'user') {
					text = 'Requesting confidence-score of fixes applied';
				} else {
					text = 'Obtained confidence-score in fixes applied';
				}
			} else if (context == 'request-shell-fix-commands-to-remember') {
				if (fromUser == 'user') {
					text = 'Summarizing the fixes applied';
				} else {
					text = 'Summarized the fixes applied';
				}
			} else if (context == 'request-shell-fix-summary') {
				if (fromUser == 'user') {
					text = 'Summarizing the fixes applied';
				} else {
					text = 'Summarized the fixes applied';
				}
			} else if (context == 'request-shell-execution-success') {
				if (fromUser == 'user') {
					text = 'Determining command success';
				} else {
					text = 'Command success determined';
				}
			} else if (context == 'summarize-conversation') {
				if (fromUser == 'user') {
					text = 'Summarizing conversation';
				} else {
					text = 'Summarized conversation';
				}
			} else if (context == 'should-fix-or-retry') {
				if (fromUser == 'user') {
					text = 'Determining command success';
				} else {
					text = 'Command success determined';
				}
			} else if (context == 'find-complexity') {
				if (fromUser == 'user') {
					text = 'Determining project complexity-score';
				} else {
					text = 'Determined project complexity-score';
				}
			} else if (context == 'request-error-summary') {
				if (fromUser == 'user') {
					text = 'Summarizing the error';
				} else {
					text = 'Summarized the error';
				}
			} else if (context == 'check-connectivity') {
				if (fromUser == 'user') {
					text = 'Checking connectivity to the hosted application';
				} else {
					text = 'Checked connectivity to the hosted application';
				}
			} else if (context == 'check-routes') {
				if (fromUser == 'user') {
					text = 'Checking routing and navigation';
				} else {
					text = 'Checked routing and navigation';
				}
			} else if (context == 'check-route-individual') {
				text = 'Checking each route';
			} else if (context == 'request-summarized-amendments') {
				if (fromUser == 'user') {
					text = 'Summarizing the amendments';
				} else {
					text = 'Summarized the amendments';
				}
			} else if (context == 'run-build-command') {
				if (fromUser == 'user') {
					text = 'Building the project';
				} else {
					text = 'Built the project';
				}
			} else if (context == 'run-hosting-command') {
				if (fromUser == 'user') {
					text = 'Starting the project';
				} else {
					text = 'Started the project';
				}
			} else if (context == 'run-installation-command') {
				if (fromUser == 'user') {
					text = 'Installing project dependencies';
				} else {
					text = 'Installed project dependencies';
				}
			} else if (context == 'run-testing-command') {
				if (fromUser == 'user') {
					text = 'Testing the project';
				} else {
					text = 'Tested the project';
				}
			}
		} else if (contentType == 'text') {
			deleteContent = false;
			if (typeof content == 'string') {
				markdown = Shared.ToString(content);
			}
		} else if (contentType == 'image') {
			deleteContent = false;
			if (file) {
				return <img className='max-w-lg' src={`data:image/png;base64,${file.content}`} alt={file.path} />;
			} else {
				text = 'File has been deleted';
			}
		}

		if (deleteContent) {
			// @ts-expect-error remove to reduce memory
			delete message.content;
		}

		if (text) {
			return (
				<div className='text-sm whitespace-pre-line'>
					{text}
					{!!markdown && Client.GetMarkdown(markdown)}
				</div>
			);
		} else if (markdown) {
			return Client.GetMarkdown(markdown);
		}

		return undefined;
	}

	static GetMarkdown(markdown: string): React.JSX.Element {
		return (
			<div
				className='text-sm whitespace-pre-line marked-down'
				dangerouslySetInnerHTML={{
					__html: marked
						.parse(markdown, { async: false })
						.replace(/(<\/.?>)\n</gi, '$1<')
						.replace(/>\n</gi, '><')
						.replace(/>\n</gi, '><')
						.trim(),
				}}
			/>
		);
	}

	static GetToolRequestMetaData(message: ChatMessage): ToolDisplayProps | undefined {
		if (message?.contentType == 'tool-request') {
			const request = message.content as ToolCallRequest;

			const args = Client.GetToolArguments(request);
			let argsExpanded = '';
			for (const key in args) {
				argsExpanded += `${key}: \`${args[key]}\``;
			}

			return {
				from: Users.Users[message.fromUser],
				message: argsExpanded,
				profileImage: Client.GetProfileImage(message.fromUser),
				toolName: request.toolName,
			};
		}
		return undefined;
	}

	static GetToolResultMetaData(message: ChatMessage): ToolDisplayProps | undefined {
		if (message?.contentType == 'tool-result') {
			const response = message.content as ToolCallResponse;
			const result: ToolDisplayProps = {
				from: { id: 'tool' as UserType, displayName: 'Tool' },
				message: response.response,
				profileImage: Client.GetProfileImage('tool'),
				toolName: response.toolName,
			};

			result.profileImage = Client.GetProfileImage(result.from.id);

			return result;
		}
		return undefined;
	}

	static DetermineElapsedBuildTime(build: BuildWithInfo) {
		let time = 0;

		if (build.steps?.length) {
			for (const step of build.steps) {
				if (step.dateStarted && step.dateFinished) {
					time += Number(new Date(step.dateFinished)) - Number(new Date(step.dateStarted));
				}
			}
		}

		return Math.ceil(time / 1000);
	}

	static DeterminePricing(buildCostSummary: BuildCostSummary) {
		const tokensIn = buildCostSummary.totalTokensInput;
		const tokensOut = buildCostSummary.totalTokensOutput;
		const tokensTotal = tokensIn + tokensOut;
		const dollarsIn = (tokensIn / Constants.ONE_MILLION) * Constants.DOLLARS_PER_MILLION_TOKENS_IN;
		const dollarsOut = (tokensOut / Constants.ONE_MILLION) * Constants.DOLLARS_PER_MILLION_TOKENS_OUT;

		const cachedTokensIn = buildCostSummary.totalTokensCachedInput;
		const cachedTokensOut = buildCostSummary.totalTokensCachedOutput;
		const cachedTokensTotal = cachedTokensIn + cachedTokensOut;
		const cachedDollarsIn = (cachedTokensIn / Constants.ONE_MILLION) * Constants.DOLLARS_PER_CACHED_MILLION_TOKENS_IN;
		const cachedDollarsOut =
			(cachedTokensOut / Constants.ONE_MILLION) * Constants.DOLLARS_PER_CACHED_MILLION_TOKENS_OUT;

		return {
			tokens: tokensTotal + cachedTokensTotal,
			total: (cachedDollarsIn + cachedDollarsOut + dollarsIn + dollarsOut).toFixed(2),
		};
	}

	static DetermineStepPricing(buildCostSummary: BuildStep) {
		const tokensIn = buildCostSummary.tokenInputCost;
		const tokensOut = buildCostSummary.tokenOutputCost;
		const tokensTotal = tokensIn + tokensOut;
		const dollarsIn = (tokensIn / Constants.ONE_MILLION) * Constants.DOLLARS_PER_MILLION_TOKENS_IN;
		const dollarsOut = (tokensOut / Constants.ONE_MILLION) * Constants.DOLLARS_PER_MILLION_TOKENS_OUT;

		const cachedTokensIn = buildCostSummary.tokenInputCachedCost;
		const cachedTokensOut = buildCostSummary.tokenOutputCachedCost;
		const cachedTokensTotal = cachedTokensIn + cachedTokensOut;
		const cachedDollarsIn = (cachedTokensIn / Constants.ONE_MILLION) * Constants.DOLLARS_PER_CACHED_MILLION_TOKENS_IN;
		const cachedDollarsOut =
			(cachedTokensOut / Constants.ONE_MILLION) * Constants.DOLLARS_PER_CACHED_MILLION_TOKENS_OUT;

		return {
			tokens: tokensTotal + cachedTokensTotal,
			total: (cachedDollarsIn + cachedDollarsOut + dollarsIn + dollarsOut).toFixed(2),
		};
	}

	static Authenticate() {
		let userId = Client.GetUserId();

		if (!userId) {
			// TODO: Auth user
			userId = Text.RandomUUID<UserId>();
			Client.SetUserId(userId);
		}
		return userId;
	}
}

const determineRecipient = (message: string) => {
	let recipientUserId: UserType | undefined = undefined;
	const tagRegex = /@[a-zA-Z]/gi;

	if (message && tagRegex.test(message)) {
		for (const user in Users.Users) {
			if (message.includes(`@${user}`)) {
				recipientUserId = user as UserType;
				break;
			}
		}
	}

	return recipientUserId ?? 'projectManager';
};
